import { Button } from "@components/common";
import getInformation from "@helpers/information/getInformation";
import { addToCart } from "@store/cart";
import React from "react";
import { useDispatch } from "react-redux";
import { Product } from "scripts/generateProductsJson";

interface UpsellCartItemProps {
  product: Product;
}

const badges = {
  "his-edibles": {
    color: "#db4545",
    title: "His",
  },
  "hers-edibles": {
    color: "#db3582",
    title: "Hers",
  },
  "recover-edibles": {
    color: "#54af9e",
    title: "Recover",
  },
  "relax-edibles": {
    color: "#FFDF5A",
    title: "Relax",
  },
  "uplift-edibles": {
    color: "#FF5B3B",
    title: "Uplift",
  },
  "night-time-edibles": {
    color: "#91BBED",
    title: "Nighttime",
  },
};

const UpsellCartItem = ({ product }: UpsellCartItemProps) => {
  const information = getInformation({ name: product.name });
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const cartItem = {
      product: {
        ...product,
      },
      quantity: 1,
    };

    dispatch(addToCart({ cartItems: [cartItem] }));
  };

  return (
    <div className="cart-body-upsell-item">
      <div className="cart-body-upsell-item-block">
        <div className="cart-body-upsell-item-img">
          <img src={information.images.main} alt={information.name} />
        </div>
        <div className="cart-body-upsell-item-content">
          <span className="cart-body-upsell-item-content-name">{information.name}</span>
          <span className="cart-body-upsell-item-content-price">${product.price} </span>

          <Button
            icon="arrow-right"
            color="white"
            onClick={handleAddToCart}
            className="cart-body-upsell-item-content-button"
          >
            Add
          </Button>
        </div>
      </div>
      <Button
        icon="arrow-right"
        color="white"
        onClick={handleAddToCart}
        className="cart-body-upsell-item-content-button-mobile"
      >
        Add
      </Button>
      <div
        className="cart-body-upsell-item-content-badge"
        style={{ backgroundColor: badges[information?.slug].color }}
      >
        {badges[information?.slug].title}
      </div>
    </div>
  );
};

export default UpsellCartItem;
