import React, {
  Dispatch,
  FC,
  SetStateAction,
  MouseEvent,
  useMemo,
  useState,
  useEffect,
} from "react";

import classNames from "classnames";

import moment from "moment-business-days";

import Button from "@components/common/Button";
import FrequentlyBrought from "@components/pages/product/purchase/common/FrequentlyBrought";
import Icon from "@components/common/Icon";

import useProduct from "@hooks/useProduct";

import getProduct from "@helpers/products/getProduct";
import getUpsellProduct from "@helpers/getUpsellProduct";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, selectCartItems, toggleCart } from "@store/cart";
import { CartItem } from "@typings/cart";
import { Information } from "scripts/generateInformationJson";
import { ProductType, SubscriptionType } from "scripts/generateProductsJson";

import axios from "axios";
import ProductTextSwitcher from "./text-switcher/ProductTextSwitcher";
import Dignities from "../dignities/Dignities";
import { getFreeGift } from "@helpers/products/getProducts";
import useModal from "@hooks/useModal";

interface Props {
  information: Information;
  quantityImages?: string[];
  oldPrice: number;
  price: number;
  isProductSelected: boolean;
  orderInfo?: any;
  isUpsell?: boolean;
  setIsProductSelected: Dispatch<SetStateAction<boolean>>;
}

const buttons: { title: string; value: SubscriptionType; badge?: any }[] = [
  {
    title: "Monthly",
    value: "MONTHLY",
    badge: {
      icon: "flame",
      text: "Most popular",
    },
  },
  {
    title: "2 Months",
    value: "EVERY_2_MONTHS",
  },
  {
    title: "3 Months",
    value: "EVERY_3_MONTHS",
  },
];

interface QuantityItem {
  title: string;
  price: string;
}

export const QUANTITY_MAP: Partial<Record<ProductType, QuantityItem[]>> = {
  EDIBLE: {
    "NightTime Edibles": [
      { title: "10 ct", price: "$2.9/ct" },
      {
        title: "20 ct",
        price: "$2.9/ct",
      },
      { title: "30 ct", price: "$3/ct" },
      { title: "40 ct", price: "$3/ct" },
    ],
    "Recover Edibles": [
      { title: "10 ct", price: "$1.9/ct" },
      { title: "20 ct", price: "$1.9/ct" },
      { title: "30 ct", price: "$1.9/ct" },
      { title: "40 ct", price: "$1.9/ct" },
    ],
    "Uplift Edibles": [
      { title: "10 ct", price: "$2.49/ct" },
      { title: "20 ct", price: "$2.49/ct" },
      { title: "30 ct", price: "$2.49/ct" },
      { title: "40 ct", price: "$2.49/ct" },
    ],
    "Relax Edibles": [
      { title: "10 ct", price: "$2.49/ct" },
      { title: "20 ct", price: "$2.49/ct" },
      { title: "30 ct", price: "$2.49/ct" },
      { title: "40 ct", price: "$2.49/ct" },
    ],
    "HERS Edibles": [
      { title: "10 ct", price: "$1.9/ct" },
      { title: "20 ct", price: "$1.9/ct" },
      { title: "30 ct", price: "$1.9/ct" },
      { title: "40 ct", price: "$1.9/ct" },
    ],
    "HIS Edibles": [
      { title: "10 ct", price: "$1.9/ct" },
      { title: "20 ct", price: "$1.9/ct" },
      { title: "30 ct", price: "$1.9/ct" },
      { title: "40 ct", price: "$1.9/ct" },
    ],
  },
  FLOWER: [
    { title: "3.5g", price: "$3.71/ct" },
    { title: "7g", price: "$5.71/ct" },
    { title: "14g", price: "$6.07/g" },
    { title: "28g", price: "$5.68/g" },
  ],
  VAPE: [{ title: "1 ct", price: "$3.50/ct" }],
};

const QuantitySelector: FC<Props> = ({
  information,
  oldPrice,
  price,
  orderInfo,
  isProductSelected,
  isUpsell,
  setIsProductSelected,
}) => {
  const [isUpgraded, setUpgraded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const cartItems = useSelector(selectCartItems);

  const { setIsOpen } = useModal();

  const nextWorkingDay = useMemo(
    () => moment(moment().date(), "mm-dd-yyyy").nextBusinessDay().format("ddd, MMM Do"),
    []
  );

  const {
    product,
    values: { quantity, subscriptionType },
    setValues,
  } = useProduct();

  const isHers = product.category === "HERS";
  const isHis = product.category === "HIS";

  const fbwProduct = getProduct({
    id: isHers ? "g90kjaajq1H6XQ6nZnlJ" : isHis ? "E210nwQ1Y4SV4LV9a96G" : "9RoJVdoKo7fkvYpoynNm",
  });

  const { price: frequentlyBroughtPrice } = fbwProduct;

  const selectedProduct = isProductSelected && product && fbwProduct;

  const handleChange = (name: string, value: string | number): void => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isFreeGiftConditionSatisfied = !!process.env.GATSBY_ENABLE_FREE_GIFT;

  const dispatch = useDispatch();

  const addToCartHandler = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    const cartItem = {
      product: {
        ...product,
        subscriptionType,
      },
      quantity: 1,
    };

    const newCartItems: CartItem[] = [cartItem];

    if (isProductSelected) {
      newCartItems.push({ product: fbwProduct, quantity: 1, linkedTo: product?.type });
    }

    if (isFreeGiftConditionSatisfied) {
      const freeGifts = getFreeGift();

      const freeGiftsItems = freeGifts.map((gift, index) => ({
        product: {
          ...gift,
        },
        isFree: true,
        quantity: 1,
      }));

      const isFreeGiftAlreadyInCart = cartItems.some((item) => item.isFree);
      const isSubscriptionNone = cartItem.product.subscriptionType === "NONE";

      if (!isFreeGiftAlreadyInCart && !isSubscriptionNone) {
        freeGiftsItems.forEach((item) => newCartItems.push(item));
      }
    }

    dispatch(addToCart({ cartItems: newCartItems }));
    dispatch(toggleCart());
  };

  const upgradeOrder = async (): Promise<void> => {
    const { subscriptionType, packageSize } = getUpsellProduct(cartItems);

    const upSellProduct = getProduct({
      name: product?.name,
      subscriptionType,
      packageSize,
    });

    try {
      setLoading(true);
      const { data } = await axios.post(`${window.location.origin}/api/checkout`, {
        ...orderInfo,
        products: [{ id: upSellProduct?.id, quantity: 1 }],
        metadata: {
          ...orderInfo.metadata,
          is_upsell: true,
        },
      });

      if (data?.message) {
        setLoading(false);
        setError(data.message);

        throw new Error(data.message);
      }

      if (data) {
        setLoading(false);
        setUpgraded(true);
      }

      setUpgraded(true);
    } catch (e: any) {
      setLoading(false);
      setError(e?.response?.data);
    }
  };

  const OrderButton = (): JSX.Element => {
    if (isUpsell) {
      return (
        <div className="order-button">
          <Button
            color="green"
            icon="sm-arrow-right"
            onClick={upgradeOrder}
            disabled={isUpgraded || loading}
            loading={loading}
          >
            {isUpgraded ? "Upgraded!" : "Upgrade my order"}
          </Button>
        </div>
      );
    }

    return (
      <Button color="orange" icon="sm-arrow-right" onClick={addToCartHandler}>
        Add to cart
      </Button>
    );
  };

  const totalPrice = useMemo(
    () => ({
      oldPrice: selectedProduct ? (selectedProduct.oldPrice || 0) + oldPrice : oldPrice,
      price: selectedProduct ? frequentlyBroughtPrice + price : price,
    }),
    [selectedProduct, oldPrice, frequentlyBroughtPrice, price]
  );

  const isVape = product.type === "VAPE";

  return (
    <div className={classNames("selector-container quantity", { "upgrade-order": isUpsell })}>
      <div className="selectors-container">
        {!isUpsell && !isVape && (
          <div className="quantity-selector">
            <div className="selector-label">1. Select Quantity:</div>
            <div className="selector-options">
              {QUANTITY_MAP[product.type]?.[product.name]?.map(({ title, badge }, i) => {
                return (
                  <button
                    className={classNames("selector-option", {
                      active: quantity === i + 1,
                    })}
                    onClick={() => handleChange("quantity", i + 1)}
                    key={i}
                    type="button"
                  >
                    {badge && (
                      <div className="selector-option-badge">
                        <Icon name={badge.icon} />
                        {badge.text}
                      </div>
                    )}
                    <span className="quantity-title">{title}</span>
                    {/* <span className="quantity-price">{price}</span> */}
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {!isUpsell && (
          <div className="delivery-frequency-selector">
            <div className="selector-label">
              <span>{isVape ? 1 : 2}. Delivery Frequency:</span>
              <span className="hint">Refill & save 25% + FREE Gummy Sample Pack</span>
            </div>
            <div className="toggle-buttons">
              <div className="month-buttons">
                {buttons.map(({ title, value, badge }) => (
                  <button
                    className={classNames("toggle-button", {
                      active: subscriptionType === value,
                    })}
                    onClick={() => handleChange("subscriptionType", value)}
                    key={value}
                    type="button"
                  >
                    {badge && (
                      <div className="toggle-button-badge">
                        <Icon name={badge.icon} />
                        {badge.text}
                      </div>
                    )}

                    <span>{title}</span>
                  </button>
                ))}
              </div>
              <button
                className={classNames("toggle-button one-time", {
                  active: subscriptionType === "NONE",
                })}
                onClick={() => handleChange("subscriptionType", "NONE")}
                type="button"
              >
                One-Time
              </button>
              <button
                className={classNames("toggle-button one-time-mobile", {
                  active: subscriptionType === "NONE",
                })}
                onClick={() => handleChange("subscriptionType", "NONE")}
                type="button"
              >
                or One Time
              </button>
            </div>
          </div>
        )}

        {subscriptionType === "NONE" && (
          <div className="subscription-benefit">
            <Icon width={48} height={48} name="sale" className="subscription-benefit__icon" />
            <p className="subscription-benefit__text">
              <b>Coupon NOT Applied:</b> Refill monthly and get <b>25% OFF</b>, plus{" "}
              <b>FREE Gummies</b> and <b>FREE Shipping.</b> Enjoy the savings every month, and
              cancel anytime!
            </p>
          </div>
        )}

        {subscriptionType !== "NONE" && !isUpsell && (
          <div className="subscription-benefit">
            <Icon width={48} height={48} name="sale" className="subscription-benefit__icon green" />
            <p className="subscription-benefit__text">
              <b>Coupon Applied: FREE</b> Gummies + <b>25% OFF</b> + <b>FREE</b> Shipping. Limited
              Time Sale, Don’t Miss Out!
            </p>
          </div>
        )}

        {!isUpsell && (
          <FrequentlyBrought
            paragraph={isVape ? 2 : 3}
            fbwProduct={fbwProduct}
            isProductSelected={isProductSelected}
            setIsProductSelected={setIsProductSelected}
          />
        )}

        {!isUpsell && <Dignities dignities={information.dignities} />}

        <div className="total-container">
          <span className="total">Total:</span>
          <div className="total-price">
            {oldPrice !== price && !!oldPrice && (
              <span className="old-price">${totalPrice.oldPrice.toFixed(2)}</span>
            )}
            <span className="price">${totalPrice.price.toFixed(2)}</span>
          </div>
        </div>
      </div>
      <div className={classNames("add-to-cart-section", { "upgrade-order": isUpsell })}>
        {isUpsell && error && !loading && (
          <div className="errors">
            <div className="error-message">{error}</div>
          </div>
        )}

        <OrderButton />
        {isUpsell && (
          <button type="button" onClick={() => setIsOpen(false)} className="cancel-upsell">
            No, I Don’t Want To Supercharge My Results
          </button>
        )}

        {/* <ProductTextSwitcher productName={product?.name} /> */}

        {!isUpsell && (
          <div className="ships-by">
            <span className="ships-by-title">Ships by</span>
            <span className="date">{nextWorkingDay}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuantitySelector;
