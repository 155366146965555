import products from "@data/products.json";

import { Product, ProductJson } from "scripts/generateProductsJson";

import { CartItem } from "@typings/cart";

const typedProducts = products as ProductJson;

const randomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const getUpsellProducts = (cartItems: CartItem[], isCart = false) => {
  const excludedIds = Array.from(new Set(cartItems.map((item) => item.product.id)));

  const filteredProducts = Object.entries(typedProducts).filter(
    ([id, product]) =>
      product.subscriptionType === "MONTHLY" &&
      product.packageSize === 1 &&
      !excludedIds.find((excluded) => excluded === id)
  );

  const edibleProducts = filteredProducts.filter(([, product]) => product.type === "EDIBLE");
  const vapeProducts = filteredProducts.filter(([, product]) => product.type === "VAPE");
  const flowerProducts = filteredProducts.filter(([, product]) => product.type === "FLOWER");

  const upsellProducts: Product[] = [];

  if (edibleProducts.length > 0) {
    upsellProducts.push(edibleProducts[randomNumber(0, edibleProducts.length - 1)][1]);
    if (isCart) {
      upsellProducts.push(edibleProducts[randomNumber(0, edibleProducts.length - 1)][1]);
    }
  }

  // if (vapeProducts.length > 0) {
  //   upsellProducts.push(vapeProducts[randomNumber(0, vapeProducts.length - 1)][1]);
  // }

  // if (flowerProducts.length > 0) {
  //   upsellProducts.push(flowerProducts[randomNumber(0, flowerProducts.length - 1)][1]);
  // }

  return upsellProducts;
};

export default getUpsellProducts;
